@import 'swiper.min.scss';

.swiper-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
}

.swiper-container-horizontal > .swiper-pagination {
    display: none;
    top: 10px;
    bottom: auto;
    width: auto;
    right: 20px;
    text-align: right;
}

.swiper-pagination-bullet {
    background: #FFFFFF;
    opacity: 1.0;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #000000;
}

.swiper-container .swiper-slide img {
    width: 100%;
}

.swiper-container .head {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    text-align: left;
    height: 45px;
    z-index: 100;
}

.swiper-container .head h2 {
    color: #FFFFFF;
    font-size: 18px;
    line-height: 45px;
    font-weight: normal;
    margin: 0px;
}

.swiper-container .content {
    position: absolute;
    top: 70px;
    left: 0px;
    width: 100%;
    text-align: left;
}

.swiper-container .head .head-inner,
.swiper-container .content .content-inner {
    padding-left: 15px;
    padding-right: 15px;
}